export default {
  fqn: 'ifm.product',
  endpoints: {
    collection: {
      get: '/products?ids[]=$ids'
    },

    entity: {
      get: '/catalog-product/$id'
    },

    transport: 'cp'
  },
}

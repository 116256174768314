export default {
  fqn: 'ifm.payment-method',
  endpoints: {
    collection: {
      get: '/payment-methods/$order_id?locale=$locale'
    },
    
    entity: {
      post: '/request-payment/$order_id?redirect=$redirect'
    },

    transport: 'cp'
  },
}
